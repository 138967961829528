import { ElementType, useId } from 'react';
import { useFormContext } from 'react-hook-form';

type FormInputWrapperProps<T, E extends React.ElementType = any> = {
  input: E;
  name: T;
} & Omit<React.ComponentProps<E>, 'name'>;

/**
 *
 * @param input React.ElementType
 * @param name T
 * @param id string | undefined
 *
 *
 * if generic argument is provided, you should provide the typeof "Component"
 * example:
 * <"email" | "phone", typeof Input>
 */
export const FormInputWrapper = <T, E extends React.ElementType>({
  input,
  name,
  id,
  ...rest
}: FormInputWrapperProps<T, E>) => {
  const Component: ElementType = input;

  const form = useFormContext();
  const error = form.formState.errors[name as string]?.message?.toString();

  const uniqueId = useId();

  const inputId = id || name || uniqueId;

  return (
    <Component
      id={inputId}
      {...form.register(name as string)}
      {...rest}
      error={error}
    />
  );
};
