'use client';

import * as React from 'react';

import { cn } from '@/lib/cn';
import { format } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { Calendar as CalendarIcon } from 'lucide-react';
import { useParams } from 'next/navigation';

import { FormDescription, FormError, Label } from '@/components/form';
import { Calendar, CalendarProps } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';

export type DatePickerProps = Omit<CalendarProps, 'date'> & {
  value?: CalendarProps['selected'];

  error?: string;
  label?: string;
  description?: string;
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
    description?: string;
    message?: string;
  };
  calendarClassNames?: CalendarProps['classNames'];
  name?: string;
  id?: string;
  placeholder?: string;
  onChange?: (event: {
    target: { name: string; value: CalendarProps['selected'] };
  }) => void;
};

export const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      className,
      value,
      mode = 'single',
      error,
      label,
      description,
      classNames,
      calendarClassNames,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const [date, setDate] = React.useState<any>(value);
    const htmlFor = props.name || props.id;
    const [locale, setLocale] = React.useState<keyof typeof Locales>('es');
    const selectedLocale = React.useMemo(() => Locales[locale], [locale]);

    const params = useParams();

    React.useEffect(() => {
      async function loadLocale() {
        setLocale((params.lang || 'es') as keyof typeof Locales);
      }

      loadLocale();
    }, [setLocale, params.lang]);

    return (
      <div className={cn('w-full space-y-1', classNames?.container)}>
        <Label htmlFor={htmlFor} className={classNames?.label}>
          {label}
        </Label>
        <Popover>
          <PopoverTrigger asChild>
            <button
              ref={ref}
              id='date'
              className={cn(
                'items-center justify-start text-left font-normal',
                'flex h-10 w-full rounded-lg border border-input bg-white px-3 py-2 text-sm text-[#30313D] ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
                !date && 'text-muted-foreground',
                className,
              )}
            >
              <CalendarIcon className='mr-2 h-4 w-4' />
              {date?.from && mode === 'range' ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y', { locale: selectedLocale })}{' '}
                    - {format(date.to, 'LLL dd, y', { locale: selectedLocale })}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y', { locale: selectedLocale })
                )
              ) : date ? (
                format(date, 'LLL dd, y', { locale: selectedLocale })
              ) : (
                <span className='text-[#707070]'>{placeholder}</span>
              )}
            </button>
          </PopoverTrigger>
          <PopoverContent className='w-auto p-0' align='start'>
            <Calendar
              locale={selectedLocale}
              mode={mode}
              classNames={calendarClassNames}
              {...(props as any)}
              selected={date}
              // @ts-ignore
              onSelect={(value) => {
                setDate(value);
                props.onChange?.({
                  target: {
                    // @ts-ignore
                    name: props.name,
                    value: value,
                  },
                });
              }}
            />
          </PopoverContent>
        </Popover>

        <FormDescription className={classNames?.description}>
          {description}
        </FormDescription>
        <FormError className={classNames?.message}>{error}</FormError>
      </div>
    );
  },
);

DatePicker.displayName = 'DatePicker';
