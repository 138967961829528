import React from 'react';

import { cn } from '@/lib/cn';

interface ContainerProps extends React.ComponentPropsWithRef<'section'> {}

export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, ...props }, ref) => {
    return (
      <section
        className={cn('container max-sm:px-4', className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Container.displayName = 'Container';
