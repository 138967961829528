import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
} from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';
import {
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';

import { cn } from '@/lib/cn';
import { useI18n } from '@/lib/i18n/client';
import * as LabelPrimitive from '@radix-ui/react-label';
import { type VariantProps, cva } from 'class-variance-authority';

interface Form<T extends FieldValues>
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  form: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
  onValidationFail?: SubmitErrorHandler<T>;
}

export const Form = <T extends FieldValues>({
  form,
  onSubmit,
  onValidationFail,
  ...rest
}: Form<T>) => {
  return (
    <FormProvider {...form}>
      <form
        {...rest}
        onSubmit={form.handleSubmit(onSubmit, onValidationFail)}
      />
    </FormProvider>
  );
};

export const FormDescription = ({
  className,
  ...props
}: ComponentProps<'p'>) => {
  if (!props.children) {
    return null;
  }

  return (
    <p className={cn('text-sm text-muted-foreground', className)} {...props} />
  );
};

export const FormError = ({
  className,
  children,
  ...props
}: ComponentProps<'p'>) => {
  const t = useI18n();

  if (!children) {
    return null;
  }

  return (
    <div>
      <small
        className={cn(
          'text-left text-sm font-medium text-destructive',
          className,
        )}
        {...props}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='-mt-1 mr-1 inline-block h-4 w-4'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          strokeWidth='2'
          stroke='currentColor'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
          <path d='M12 8v4' />
          <path d='M12 16h.01' />
        </svg>
        {typeof children === 'string'
          ? t(children as keyof typeof t)
          : children}
      </small>
    </div>
  );
};

const labelVariants = cva(
  'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
);

export const Label = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => {
  if (!props.children) {
    return null;
  }

  return (
    <LabelPrimitive.Root
      ref={ref}
      className={cn(labelVariants(), className)}
      {...props}
    />
  );
});
Label.displayName = LabelPrimitive.Root.displayName;
