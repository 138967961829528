'use client';

import { useScopedI18n } from '@/lib/i18n/client';
import { BannerEvent } from '@/services/events/get-events-banner';
import { Calendar, MapPin, Ticket, TicketCheck } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/pagination';
import { EffectCards, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from '@/components/ui/button';

interface Props {
  events: BannerEvent[];
  className?: string;
}

export const HomeSwiper = ({ events, className }: Props) => {
  const t = useScopedI18n('home');

  return (
    <Swiper
      effect='cards'
      grabCursor
      loop
      pagination={{ dynamicBullets: false }}
      modules={[EffectCards, Pagination]}
      className={className}
    >
      {events.map((event) => (
        <SwiperSlide key={event.eveCodigo}>
          <Image
            src={event.imgURL}
            alt={event.eveNombre}
            width={600}
            height={270}
            priority
            quality={100}
            className='min-h-48 object-cover max-md:hidden md:min-h-fit'
          />

          <Image
            src={event.imgSmallURL}
            alt={event.eveNombre}
            width={398}
            height={192}
            priority
            quality={100}
            className='min-h-48 object-cover md:hidden'
          />

          <div className='flex h-full flex-col justify-between p-4 md:mt-2'>
            <div>
              <h2 className='flex text-xl md:text-2xl'>
                <Ticket className='mr-2 mt-0.5 h-5 w-5 md:mt-0 md:h-8 md:w-8' />
                {event.eveNombre}
              </h2>

              <p className='mt-4 flex text-gray-400 md:mt-6 md:text-lg'>
                <Calendar className='mr-2 mt-0.5 h-5 w-5 md:mt-1' />
                {event.primeraFecha}
              </p>

              <p className='mt-1 flex text-gray-400 md:text-lg'>
                <MapPin className='mr-2 mt-0.5 h-5 w-5 md:mt-1' />
                {event.location}
              </p>
            </div>

            <div className='flex justify-end'>
              <Button className='mt-2 md:mt-4' asChild>
                <Link
                  href={
                    event.eventos > 1
                      ? `/event-dates/${event.eveSlug}`
                      : `/${event.efeModoCompra === 'map' ? 'map' : 'event'}/${event.eveSlug}/${event.efeCodigo || 100}`
                  }
                >
                  <TicketCheck className='mr-2' />

                  {t('buyTickets')}
                </Link>
              </Button>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
