import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/public/ticker_white.svg");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlipWords"] */ "/home/vsts/work/1/s/src/components/ui/flip-words.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/containers/home/home-filter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeSwiper"] */ "/home/vsts/work/1/s/src/containers/home/home-swiper.tsx");
