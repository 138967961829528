import * as React from 'react';

import { cn } from '@/lib/cn';

import { FormDescription, FormError, Label } from '../form';

export interface InputProps extends React.ComponentProps<'input'> {
  error?: string;
  label?: string;
  description?: string;
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
    description?: string;
    message?: string;
  };
  name?: string;
  mask?: string;
  maskReplacement?: Record<string, RegExp>;
  button?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      classNames,
      description,
      error,
      mask,
      maskReplacement,
      label,
      button,
      ...props
    },
    ref,
  ) => {
    const htmlFor = props.id || props.name;

    return (
      <div className={cn('w-full space-y-1', classNames?.container)}>
        <Label htmlFor={htmlFor} className={classNames?.label}>
          {label}
        </Label>
        <div className='relative'>
          <input
            ref={ref}
            className={cn(
              'flex h-10 w-full rounded-lg border border-input px-3 py-2 text-sm text-[#30313D] ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
              {
                'border-destructive focus-visible:ring-destructive': error,
              },
              classNames?.input,
              className,
            )}
            {...props}
          />

          {button}
        </div>
        <FormDescription className={classNames?.description}>
          {description}
        </FormDescription>
        <FormError className={classNames?.message}>{error}</FormError>
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
